html {
  height: 100%;
}

body {
  background-color: #f2f2f2 !important;
}

.loginBackground {
  background-image: url("https://major-projects-directory.s3.ca-central-1.amazonaws.com/Images/CloudyForestBackground.jpg"); 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: '100vw';
  height: '100vh';
}

.contentContainer {
  min-height: 65vh;
}

.react-bootstrap-table table {
  overflow-x: auto;
  table-layout: auto !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar {
  background-color: #00815d !important;
}

#navdropdown-text{
  color: black;
}

#left-text-align{
  text-align: left !important;
}

#footer-container{
  background-color: #00815d !important;
}

.footer-text{
  color: white;
  text-decoration:none;
}

.export-button{
  margin-top: 25px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
